import React, {useState, useEffect, useRef} from "react";
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from "react-redux";

//style
import "./baseline_scan.css";
//components
import VideoStreamSocket from "../../components/scanner/socket/socket";
import SimpleLoader from "../../components/scanner/loader/simple-loader";
import Lobby from "../../components/scanner/foreplay/lobby";
import ScanStep from "../../components/scanner/foreplay/obi_one";
import ScanVideoStep from "../../components/scanner/foreplay/obi_one_vid";
import CamSettingsLoader from "../../components/scanner/foreplay/load_cam_settings";
import FeedbackBlock from "../../components/scanner/foreplay/feedback_chain";
import MainLoader from "../../components/loaders/main_loader/main_loader";
import VideoCamera, { CAM_TYPE_TENANT_SCAN, CAM_TYPE_TENANT_SCAN_NO_BASE } from "../../components/scanner/camera/videoCamera";
import InteractiveVideoCameraV2 from "../../components/scanner/camera/interactiveVideoCameraV2";
import ScannerError from "../../components/errorMessages/scannerError/scannerError";
import VideoUploader from "../../components/uploadVideo";
import MasterPopup from "../../components/popups/main_popup";
import Btn from "../../components/buttons/standard/btn";
//containers
//assets
// import howToVid from "../../assets/scan_page/TenantScan-HowTo.mp4"
import howToVid from "../../assets/scan_page/TenantScan-HowToV2.mp4"
import step1x1 from "../../assets/scan_page/step1.jpg"
import step1x2 from "../../assets/scan_page/step1@2x.jpg"
import step1x3 from "../../assets/scan_page/step1@3x.jpg"
import step2x1 from "../../assets/scan_page/step2.jpg"
import step2x2 from "../../assets/scan_page/step2@2x.jpg"
import step2x3 from "../../assets/scan_page/step2@3x.jpg"
import step3x1 from "../../assets/scan_page/step3.jpg"
import step3x2 from "../../assets/scan_page/step3@2x.jpg"
import step3x3 from "../../assets/scan_page/step3@3x.jpg"
//utils
import { isIOS, postReqOptBuilder } from "../../utils/main_utils";
import { blobToFile, fetchPresignedUrls, getFileExtFromBlob, uploadFile } from "../../utils/vid_upload_utils";
//slices
import { postLogEntry } from "../../store/slices/activitySlice";
import VideoCameraIOS from "../../components/scanner/camera/iosVideoCamera";
import InteractiveVideoCameraV3 from "../../components/scanner/camera/interactiveVideoCameraIOS";
import Notification from "../../components/side_notification/side_notification";
//constants
const howToVid_NoBaseline = "https://paraspot-b2b-users.s3.eu-central-1.amazonaws.com/public/Baseline-HowToV2.mp4";
const FPS = 20;
const CAMERA_CONSTRAINTS = {
    video: {
        facingMode: { exact: 'environment' },
        frameRate: { ideal: FPS },
        aspectRatio: 16/9,
        zoom: 1,
        width: { ideal: 1920 },
        height: { ideal: 1080 },
        // ...(isIOS() ? {
        //     width: { ideal: 1920 },
        //     height: { ideal: 1080 },
        // } : {})
    },
    audio: false
};
const STEP_LOBBY = 0;
const STEP_VID = 6;
const STEP_EXP1 = 1;
const STEP_EXP2 = 2;
const STEP_EXP3 = 3;
const STEP_SCAN = 4;
const STEP_DONE = 5;
const STEP_FEEDBACK_1 = 7;
const STEP_FEEDBACK_2 = 8;
const STEP_RETRY_FILE_UPLOAD = 9;
const STEP_LOADING_ISSUE = 10;
const STEP_LOADING_ISSUE_RESOLVED = 11;
const STEP_UPLOAD_FAILED = 12;

export default function TenantScanV2 (props) {
    const dispatch = useDispatch();
    const endUserData = useSelector(state => state.config.endUserData);

    const scanID = props.match.params.scan_id;
    const clientBeName = props.match.params.cbe_name;
    const inspection_type = props.inspection_type ? props.inspection_type : props.match.params.inspection_type;
    const base_url = props.base_url;
    const debugMode = (new URLSearchParams(window.location.search)).get('debug') === '1';
    const autoApply = (new URLSearchParams(window.location.search)).get('auto_apply');
    const redirectURL = (new URLSearchParams(window.location.search)).get('redirect_url');
    // state based variables
    const [loadingPage, setLoadingPage] = useState(true);
    const [lang, setLang] = useState(null);
    const [showErrorScreen, setShowErrorScreen] = useState(false);
    const [finalizingScan, setFinalizingScan] = useState(false);
    const [loadedPercentage, setLoadedPercentage] = useState(0);
    const [videoDeviceDets, setVideoDeviceDets] = useState(null);
    const [step, setStep] = useState(0);
    const [notifState, setNotifState] = useState(null);
    const [popupState, setPopupState] = useState(null);
    const [blockClosePopup, setBlockClosePopup] = useState(false);
    // video upload related
    const [recordedBlob, setRecordedBlob] = useState(null);
    const [recordingUploadRate, setRecordingUploadRate] = useState(0);
    const [uploadLoaderStatus, setUploadLoaderStatus] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [networkConnState, setNetworkConnState] = useState(navigator.onLine);
    const [medRecOptionSelected, setMedRecOptions] = useState(null);
    const [debugStateStatus, setDebugStateStatus] = useState(null);
    // Screen specific state variables
    const [clientLogo, setClientLogo] = useState(null);
    const [pid, setPID] = useState(null);
    const [scanData, setScanData] = useState(null);
    const [clientFn, setClientFn] = useState(null);
    const [unitAddress, setUnitAddress] = useState(null);
    // general variables
    let scanSess = useRef(null);

    
    const handleCloseNotif = () => {
        setNotifState(null);
    }

    const switchStep = (next_step) => {
        console.log("Switching Step");
        let permitSwitch = false;
        if (next_step === STEP_DONE) {
            if (step === STEP_SCAN) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_FEEDBACK_1) {
            if (step === STEP_DONE) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_FEEDBACK_2) {
            if (step === STEP_FEEDBACK_1) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_LOADING_ISSUE) {
            if (finalizingScan && step !== STEP_FEEDBACK_1 && step !== STEP_FEEDBACK_2 && step !== STEP_DONE) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_RETRY_FILE_UPLOAD) {
            if (step === STEP_LOADING_ISSUE || step === STEP_UPLOAD_FAILED) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_UPLOAD_FAILED) {
            if (step === STEP_LOADING_ISSUE || step === STEP_RETRY_FILE_UPLOAD) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_LOADING_ISSUE_RESOLVED) {
            if (step === STEP_LOADING_ISSUE) {
                permitSwitch = true;
            }
        } else {
            permitSwitch = true;
        }
        if (permitSwitch) {
            setStep(next_step);
        } else {
            console.log(`Switch step from ${step} to ${next_step} is not permitted`);
        }
    };

    const getFinalConstraints = () => {
        if (videoDeviceDets !== true && videoDeviceDets !== false) {
            return {
                video: {
                    ...CAMERA_CONSTRAINTS.video,
                    deviceId: videoDeviceDets.deviceId
                },
                audio: CAMERA_CONSTRAINTS.audio
            }
        } else return CAMERA_CONSTRAINTS
    }

    const showError = (errMsg=true) => {
        setShowErrorScreen(errMsg);
    };

    const uploadRecording = (sid) => {
        if (uploadStatus === 'done') {
            console.log("Upload already done. Skipping...");
            return;
        } else if (uploadStatus === 'ongoing') {
            console.log("Upload is ongoing. Skipping...");
            return;
        }
        const lastStatus = uploadStatus ? (uploadStatus + "") : null;
        setUploadStatus('ongoing');
        
        // Uploading the recorded scan
        const fileToUpload = blobToFile(recordedBlob, sid);
        fetchPresignedUrls(props.para_be, fileToUpload, `${scanID}_${inspection_type}_${pid}`)
        .then(({ upload_id, presigned_urls }) => {
            uploadFile(
                props.para_be, fileToUpload, upload_id, presigned_urls, `${scanID}_${inspection_type}_${pid}`, 
                (uploadRate) => {
                    setLoadedPercentage(uploadRate);
                    if (step === STEP_LOADING_ISSUE) switchStep(STEP_LOADING_ISSUE_RESOLVED);
                }, 
                setUploadLoaderStatus, setNotifState, 
                () => {
                    setUploadStatus('done');
                    switchStep(STEP_DONE);
                }, 
                () => {
                    setUploadStatus(lastStatus === 'fail' ? 'fail2' : 'fail');
                },
                () => {
                    if (step === STEP_LOADING_ISSUE) switchStep(STEP_RETRY_FILE_UPLOAD);
                }, setRecordingUploadRate
            );
        })
        .catch((error) => {
            console.error("An error occurred while uploading recorded scan:", error);
            setUploadStatus(lastStatus === 'fail' ? 'fail2' : 'fail');
        });
    };

    const finalizeScan = (sid, intervalCount) => {
        setFinalizingScan(true);
        // console.log("[Tenant Scan] Finalizing scan!");
        dispatch(postLogEntry( 
            { 
                activityID: `${pid}--${scanID}--${inspection_type}--${sid}`, activityType: 'pid--scanID--inspectionType--sessionID', 
                ip: endUserData?.ip, action: {action: "done", target: "scan", extra: {frames: intervalCount}} 
            }
        ));
    };

    const prepInstructions = (tmpScanData) => {
        if (typeof tmpScanData?.scan_data === "string") tmpScanData.scan_data = JSON.parse(tmpScanData.scan_data);
        if (typeof tmpScanData?.scan_metadata === "string") tmpScanData.scan_metadata = JSON.parse(tmpScanData.scan_metadata);
        if (typeof tmpScanData?.uni_frames === "string") tmpScanData.uni_frames = JSON.parse(tmpScanData.uni_frames);
        if (typeof tmpScanData?.uf_data === "string") tmpScanData.uf_data = JSON.parse(tmpScanData.uf_data);
        if (tmpScanData?.scan_metadata) tmpScanData['instructions'] = tmpScanData.scan_metadata.scan_session.finalInstructions;
        setScanData(tmpScanData);
    };

    const handleClosePopup = (refresh=false) => {
        if (!blockClosePopup) {
            setPopupState(null);
        }
    };

    const changeBlockStatus = (isBlocked) => {
        setBlockClosePopup(isBlocked);
    };

    const handleUploadVideo = (pid) => {
        setPopupState([
            <VideoUploader 
                customText={<>You experienced an issue with performing the scan?<br/>Click to upload a video of the scan</>} 
                pid={pid} 
                changeBlockStatus={changeBlockStatus} 
                closeFunc={handleClosePopup} 
                onUploadComplete={() => {
                    setTimeout(() => {
                        handleClosePopup();
                        setStep(STEP_DONE);
                    }, 3000);
                }}
                para_be={props.para_be} />, 
            {closeFunc: handleClosePopup, extraClasses: "has-bsp-container"}
        ]);
    };

    const downloadScanFile = () => {
        try {
            if (!recordedBlob) throw new Error("No scan file to download");
            if (recordedBlob.size === 0) throw new Error("Empty scan file");
            if (!recordedBlob.type || recordedBlob.type === "") throw new Error("Blob type is empty or undefined.");
            
            const link = document.createElement('a');
            const objectURL = (window.webkitURL || URL).createObjectURL(recordedBlob);
            link.href = objectURL;
            link.download = `${scanSess.current}.${getFileExtFromBlob(recordedBlob)}`;
    
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setTimeout(() => {
                console.log("Revoking object url to free up memory");
                (window.webkitURL || URL).revokeObjectURL(objectURL);
            }, 5000);
        } catch (e) {
            console.log("Something went wrong while downloading the scan file:\n", e);
            setNotifState({type: "error", msg: "Failed to download the scan file"});
        }
    };

    useEffect(() => {
        fetch(props.para_be + '/scan/authenticateScan', postReqOptBuilder({'scanID': scanID, 'clientBeName': clientBeName}))
            .then(response => response.json())
            .then(response => {
                console.log(response);
                if(response.status === 200) {
                    setPID(response.result.pid);
                    setClientLogo(response.result.logo);
                    setClientFn(response.result.name);
                    setUnitAddress(response.result.address);
                    console.log("Starting prep instructions");
                    prepInstructions(response.result);
                } else {
                    //TODO - set error
                    console.log("Error");
                    setShowErrorScreen(true);
                }
                setLoadingPage(false);
            })
            .catch ( error => { //TODO - add error handling
                console.log("Exception was thrown while authenticating scan");
                console.log(error);
                setShowErrorScreen("Something went wrong. Please try again later.");
            });

        // getClientData().then(data => {
        //     setendUserData(data);
        // });
        window.onbeforeunload = function() {
            return "Data will be lost if you leave the page. Are you sure?";
        };
        window.addEventListener('online', () => { setNetworkConnState(true); })
        window.addEventListener('offline', () => { setNetworkConnState(false); })
    }, []);

    useEffect(() => {
        if (pid) {
            dispatch(postLogEntry( 
                { 
                    activityID: `${pid}--${scanID}--${inspection_type}`, activityType: 'pid--scanID--inspectionType', 
                    ip: endUserData?.ip, action: {action: "open", target: "scan"} 
                }
            ));
        }
    }, [endUserData, inspection_type, pid]);

    useEffect(() => {
        console.log("scanData has changed");
        console.log(scanData);
        console.log(!scanData || !scanData.instructions || !scanData.uni_frames);
        // if (!scanData || !scanData.instructions || !scanData.uni_frames) {
        if (!scanData) {
            console.log("Showing Error...");
            showError();
        } else setShowErrorScreen(false);
    }, [scanData]);

    useEffect(() => {
        if (videoDeviceDets === false) {
            setShowErrorScreen("Cannot perform scan on this device. There is no back facing camera available.");
        }
    }, [videoDeviceDets]);

    useEffect(() => {
        if (recordedBlob) {
            console.log("New scan file recorded. Uploading...");
            if (recordedBlob.size === 0) {
                console.log("[!!DEBUG/ERROR] Empty scan file");
            } else if (!recordedBlob.type || recordedBlob.type === "") {
                console.log("[!!DEBUG/ERROR] Blob type is empty or undefined.");
            } else {
                setTimeout(() => {
                    uploadRecording(scanSess.current);
                }, 700);
            }
        } else {
            console.log("No scan file recorded yet.");
        }
    }, [recordedBlob]);

    useEffect(() => {
        if (uploadStatus === "fail") {
            setNotifState({"type": "error", "msg": "Trying to work through your network issues."});
            setTimeout(() => {
                uploadRecording(scanSess.current);
            }, 3000);
        }
    }, [uploadStatus]);

    useEffect(() => {
        if (step === STEP_SCAN) {
            const startWait = new Date();
            while (((new Date()) - startWait) < 5000) {
                if (scanSess.current !== null) {
                    dispatch(postLogEntry( 
                        { 
                            activityID: `${pid}--${scanID}--${inspection_type}--${scanSess.current}`, activityType: 'pid--scanID--inspectionType--sessionID', 
                            ip: endUserData?.ip, action: {action: "start", target: "scan"} 
                        }
                    ));
                    break;
                }
            }
        } else if (step === STEP_DONE) {
            dispatch(postLogEntry( 
                { 
                    activityID: `${pid}--${scanID}--${inspection_type}--${scanSess.current}`, activityType: 'pid--scanID--inspectionType--sessionID', 
                    ip: endUserData?.ip, action: {action: "uploaded", target: "scan"} 
                }
            ));
            setTimeout(() => {
                switchStep(STEP_FEEDBACK_1)
            }, 2000);
        } else if (step === STEP_LOADING_ISSUE) {
            dispatch(postLogEntry( 
                { 
                    activityID: `${pid}--${scanID}--${inspection_type}--${scanSess.current}`, activityType: 'pid--scanID--inspectionType--sessionID', 
                    ip: endUserData?.ip, action: {action: "upload-issue", target: "scan"} 
                }
            ));
        } else if (step === STEP_LOADING_ISSUE_RESOLVED) {
            dispatch(postLogEntry( 
                { 
                    activityID: `${pid}--${scanID}--${inspection_type}--${scanSess.current}`, activityType: 'pid--scanID--inspectionType--sessionID', 
                    ip: endUserData?.ip, action: {action: "upload-issue-resolved", target: "scan"} 
                }
            ));
        }
        else if (step === STEP_UPLOAD_FAILED) {
            dispatch(postLogEntry( 
                { 
                    activityID: `${pid}--${scanID}--${inspection_type}--${scanSess.current}`, activityType: 'pid--scanID--inspectionType--sessionID', 
                    ip: endUserData?.ip, action: {action: "upload-failed", target: "scan"} 
                }
            ));
        }
    }, [step]);


    return (
        <section className={`t-scan-main`}>
            <Helmet>
                <title>Checkout {unitAddress || ""} | {clientFn || ""} | Paraspot</title>
                <meta name="description" content={`Perform contactless checkout from ${unitAddress} using our AI scan.`}/>
                <meta property="og:title" content={`Checkout ${unitAddress} | ${clientFn} | Paraspot`}/>
                <meta property="og:description" content={`Perform contactless checkout from ${unitAddress} using our AI scan.`}/>
            </Helmet>

            {debugMode &&
                <div className="debug-layer">
                    <div className="sockets-view">
                        <p className="text-3">
                        Network Status: {networkConnState ? "Online" : "Offline"}
                        <br/>
                        Debug Status: {debugStateStatus ? debugStateStatus : "N/A"}
                        <br/>
                        Media Recorder Options: {medRecOptionSelected ? JSON.stringify(medRecOptionSelected) : "N/A"}
                        </p>
                    </div>
                </div>
            }

            {
                loadingPage ?
                    <MainLoader/> :
                !pid ?
                    <ScannerError errMsg={<>Error!<br/>Invalid scan ID or client name</>}/> :
                showErrorScreen !== false ?
                    <ScannerError errMsg={showErrorScreen === true ? undefined : showErrorScreen}/> :
                step === STEP_LOBBY ?
                    <Lobby
                        includeClientLogo={true}
                        clientLogo={clientLogo && clientLogo.includes("https://") ? clientLogo : `${base_url}/${clientLogo}`}
                        onNext={(selectedLang) => {
                                setLang(selectedLang);
                                switchStep(STEP_VID);
                            }}
                        btnText="Next"
                        langSelection={true}
                        onHelp={() => handleUploadVideo(`${scanID}_${inspection_type}_${pid}`)}
                    /> :
                    // img, text, btnText, onSkip, onBtnClick, step, totalSteps
                step === STEP_VID && videoDeviceDets === null ?
                    <CamSettingsLoader onFinishedLoading={setVideoDeviceDets} /> :
                step === STEP_VID ?
                    <ScanVideoStep
                        vid={scanData && scanData?.instructions ? howToVid : howToVid_NoBaseline}
                        text={
                            lang === "es" ?
                            <p>Aquí tienes un video explicativo sobre cómo usar el SmartScanner</p> :
                            <p>Here is an explanation video on how to use the SmartScanner</p>
                        }
                        btnText={lang === "es" ? "Siguiente" : "Next"}
                        onBtnClick={() => switchStep(STEP_EXP1)}
                        step={1}
                        totalSteps={4}
                    /> :
                step === STEP_EXP1 ?
                    <ScanStep
                        img={step1x1}
                        imgx2={step1x2}
                        imgx3={step1x3}
                        text={
                            lang === "es" ?
                            <p>
                                Para completar su {inspection_type === "checkin" ? "entrada" : "salida"}, le pedimos amablemente que grabe un video corto del apartamento.<br/>
                                Este paso rápido y sencillo asegura un registro claro y transparente del estado del apartamento en el momento de su {inspection_type === "checkin" ? "entrada" : "salida"}.
                            </p> :
                            <p>
                                To complete your {inspection_type === "checkin" ? "move-in" : "move-out"}, we kindly ask you to take a short video of the unit.<br/>
                                This quick and easy step ensure a clear and transparent record of the apartment's condition at the time of your {inspection_type === "checkin" ? "move-in" : "move-out"}.
                            </p>
                        }
                        btnText={lang === "es" ? "Siguiente" : "Next"}
                        onBtnClick={() => switchStep(STEP_EXP2)}
                        step={1}
                        totalSteps={4}
                    /> :
                step === STEP_EXP2 ?
                    <ScanStep
                        img={step2x1}
                        imgx2={step2x2}
                        imgx3={step2x3}
                        text={
                            lang === "es" ? 
                            <p>
                                <b>Antes de</b> comenzar el escaneo, tome las siguientes medidas para una experiencia fluida y precisa: <br/>
                                    1. Conéctese a una conexión de internet estable. (Preferentemente WiFi)<br/>
                                    2. Mantenga su teléfono en posición vertical en todo momento. <br/>
                                    3. Encienda las luces en todas las habitaciones. <br/>
                                    4. Comience en la entrada. <br/>
                                    5. Muévase a un ritmo constante y evite movimientos bruscos. <br/>
                                    6. Escanee cada habitación a fondo.
                            </p> :
                            <p>
                                <b>Prior to</b> starting the scan take the following measures for a smooth and accurate experience<br/>
                                    1. Connect to a stable internet connection. (Preferably WiFi)<br/>
                                    2. Hold your phone vertically at all times.<br/>
                                    3. Turn on the lights in all rooms.<br/>
                                    4. Begin at the entrance.<br/>
                                    5. Move at a stead pace and avoid abrupt movements.<br/>
                                    6. Scan each room thoroughly.
                            </p>
                        }
                        btnText={lang === "es" ? "Siguiente" : "Next"}
                        onBtnClick={() => switchStep(STEP_EXP3)}
                        step={2}
                        totalSteps={4}
                    /> :
                step === STEP_EXP3 ?
                    <ScanStep
                        img={step3x1}
                        imgx2={step3x2}
                        imgx3={step3x3}
                        text={
                            lang === "es" ? 
                            <p>
                                ¡Seguir estos consejos asegurará que su experiencia de escaneo sea rápida, eficiente y precisa!<br/>
                                Ahora haga clic en "Empecemos" {scanData && scanData?.instructions ? "" : " y presione el botón de grabar"} para comenzar el escaneo.
                            </p> : 
                            <p>
                                Following these tips will ensure your scanning experience will be quick, efficient, and accurate!<br/>
                                Now click "Let's Start"{scanData && scanData?.instructions ? "" : " and press the record button"} to begin the scan.
                            </p>
                        }
                        btnText={lang === "es" ? "Empecemos" : "Let's Start"}
                        onSkip={null}
                        onBtnClick={() => switchStep(STEP_SCAN)}
                        step={3}
                        totalSteps={4}
                    /> :
                step === STEP_SCAN ?
                    (scanData?.instructions ?
                        <InteractiveVideoCameraV3
                            FPS={FPS}
                            cameraConstraints={getFinalConstraints()}
                            finalizationCallback={finalizeScan}
                            showError={showError}
                            endUserInfo={{
                                    'pid': pid, 
                                    'uf': {'dir': scanData.uni_frames[0].split("/").pop().split("?")[0], 'len': scanData.uni_frames.length}, 
                                    'bs_scan_data': JSON.stringify(scanData.scan_metadata.scan_session),
                                    'inspection_type': inspection_type
                                }}
                            camType={CAM_TYPE_TENANT_SCAN}
                            tenantScanData={[
                                    scanData && scanData.instructions ? scanData.instructions : null, 
                                    scanData && scanData.uni_frames ? scanData.uni_frames : null, 
                                    scanData && scanData.uf_data ? scanData.uf_data : null,
                                    scanData && scanData.scan_metadata?.scan_session?.skipIndexes ? scanData.scan_metadata.scan_session.skipIndexes : [],
                                ]}
                            lang={lang}
                            setRecordedBlob={setRecordedBlob}
                            para_be={props.para_be}
                            transmitSid={(sid) => { scanSess.current = sid; }}
                            setMedRecOptions={setMedRecOptions}
                        /> : 
                        <VideoCameraIOS 
                            FPS={FPS}
                            cameraConstraints={getFinalConstraints()}
                            finalizationCallback={finalizeScan}
                            showError={showError}
                            endUserInfo={{
                                    'pid': pid, 
                                    'inspection_type': inspection_type,
                                    'auto_approve': autoApply
                                }}
                            camType={CAM_TYPE_TENANT_SCAN_NO_BASE}
                            showTimeStamp={true}
                            setRecordedBlob={setRecordedBlob}
                            para_be={props.para_be}
                            transmitSid={(sid) => { scanSess.current = sid; }}
                            setMedRecOptions={setMedRecOptions}
                        />
                    ) :
                step === STEP_DONE ? 
                    <SimpleLoader 
                        loaderSuccess={true} 
                        msg={<><b>Well Done!</b><br/>You've cleared the scan</>}
                        successCallback={redirectURL && (() => { window.location.href = redirectURL; })}
                        {...(recordedBlob ?
                            {
                                extraElementEnd: <div className="mt-2">
                                    <Btn 
                                        type="secondary"
                                        style={{
                                            marginTop: '20px', 
                                            marginBottom: '10px'
                                        }} 
                                        text="Download Scan"
                                        onclick={downloadScanFile}
                                    />
                                </div>
                            } : {}
                        )}
                    /> :
                step === STEP_FEEDBACK_1 ?
                    <FeedbackBlock 
                        feedbackQuestion="How would you rate your scanning experience?"
                        includeClientLogo={true}
                        clientLogo={clientLogo && clientLogo.includes("https://") ? clientLogo : `${base_url}/${clientLogo}`}
                        onNext={(selectedRate) => {
                                switchStep(STEP_FEEDBACK_2);
                                fetch(props.para_be + '/scan/rate-your-scan', postReqOptBuilder({
                                    'scan_id': scanID, 
                                    'pid': pid, 
                                    'scan_rating': selectedRate, 
                                    'ip_address': endUserData?.ip
                                }))
                                .then(response => response.json())
                                .then(response => {
                                    console.log(response);
                                    if(response.status === 200) {
                                        console.log("Rating Sent");
                                    } else {
                                        //TODO - set error
                                        console.log("Failed sending rating");
                                    }
                                })
                                .catch ( error => { //TODO - add error handling
                                    console.log("Exception was thrown while sending scan rating");
                                    console.log(error);
                                });
                            }}
                    /> :
                (step === STEP_FEEDBACK_2 && 
                    <FeedbackBlock 
                        feedbackQuestion="How would you rate your stay?"
                        thankForFeedback={true}
                        includeClientLogo={true}
                        clientLogo={clientLogo && clientLogo.includes("https://") ? clientLogo : `${base_url}/${clientLogo}`}
                        onNext={(selectedRate) => {
                            fetch(props.para_be + '/scan/rate-your-scan', postReqOptBuilder({
                                'scan_id': scanID, 
                                'pid': pid, 
                                'stay_rating': selectedRate, 
                                'ip_address': endUserData?.ip
                            }))
                            .then(response => response.json())
                            .then(response => {
                                console.log(response);
                                if(response.status === 200) {
                                    console.log("Rating Sent");
                                } else {
                                    //TODO - set error
                                    console.log("Failed sending rating");
                                }
                            })
                            .catch ( error => { //TODO - add error handling
                                console.log("Exception was thrown while sending scan rating");
                                console.log(error);
                            });
                            }}
                    />
                )
            }
            {step !== STEP_DONE && step !== STEP_FEEDBACK_1 && step !== STEP_FEEDBACK_2 && finalizingScan ? 
                (
                    <SimpleLoader 
                        loadedPercentage={
                            (step === STEP_UPLOAD_FAILED ? 
                                null :
                                loadedPercentage
                            )
                        } 
                        msg={
                            step === STEP_LOADING_ISSUE ?
                                <>
                                    Something Went Wrong<br/>
                                    <div className="text-0">Do not leave the screen.</div>
                                    {(recordedBlob && recordingUploadRate) ?
                                        <div className="text-5">
                                            Scan Size: {Math.round(recordedBlob.size / 1024 / 1024 * 100) / 100} MB
                                            <br/>
                                            Estimated Time Left: {Math.floor(((recordedBlob.size / 1024 / 1024) * (100 - loadedPercentage) / 100) / recordingUploadRate)} Seconds
                                            <br/>
                                            Upload Rate: {Math.round(recordingUploadRate * 100) / 100} MB/s
                                        </div> : ""
                                    }
                                    <br/>
                                    <div className="text-3">
                                        We noticed an issue with your network connection.<br/>
                                        Please hang tight while we try to resolve it.
                                    </div>
                                </> : 
                            (step === STEP_RETRY_FILE_UPLOAD ?
                                <>
                                    Loading<br/>
                                    <div className="text-0">Do not leave the screen</div>
                                    {(recordedBlob && recordingUploadRate) ?
                                        <div className="text-5">
                                            Scan Size: {Math.round(recordedBlob.size / 1024 / 1024 * 100) / 100} MB
                                            <br/>
                                            Estimated Time Left: {Math.floor(((recordedBlob.size / 1024 / 1024) * (100 - loadedPercentage) / 100) / recordingUploadRate)} Seconds
                                            <br/>
                                            Upload Rate: {Math.round(recordingUploadRate * 100) / 100} MB/s
                                        </div> : ""
                                    }
                                    <br/>
                                    <div className="text-3">
                                        The network issue persists.<br/>
                                        Please hold on for a bit longer as we are trying to establish connection.
                                    </div>
                                </> :
                            (step === STEP_LOADING_ISSUE_RESOLVED ?
                                <>
                                    Loading<br/>
                                    <div className="text-0">Do not leave the screen</div>
                                    {(recordedBlob && recordingUploadRate) ?
                                        <div className="text-5">
                                            Scan Size: {Math.round(recordedBlob.size / 1024 / 1024 * 100) / 100} MB
                                            <br/>
                                            Estimated Time Left: {Math.floor(((recordedBlob.size / 1024 / 1024) * (100 - loadedPercentage) / 100) / recordingUploadRate)} Seconds
                                            <br/>
                                            Upload Rate: {Math.round(recordingUploadRate * 100) / 100} MB/s
                                        </div> : ""
                                    }
                                    <br/>
                                    <div className="text-3">Issue resolved! You should be on  your way in just a bit. Thank you for your patience!</div>
                                </> :
                            (step === STEP_UPLOAD_FAILED ?
                                <>
                                    Upload Failed<br/>
                                    <div className="text-0">Network Connectivity Issue</div><br/>
                                    <div className="text-3">
                                        Your network connection is unavailable or unstable.<br/>
                                        We appreciate your patience as we tried to mediate the issue on your device.<br/>
                                        Please download the scan, by clicking on the Download Scan button below, and upload it once you are connected to a stable network.<br/>
                                        You may also retry to upload the scan by clicking on the Retry button below.<br/><br/>
                                        Thank you for your cooperation!
                                    </div>
                                </> :
                                <>
                                    Loading<br/>
                                    <div className="text-0">Do not leave the screen.</div>
                                    {(recordedBlob && recordingUploadRate) ?
                                        <div className="text-5">
                                            Scan Size: {Math.round(recordedBlob.size / 1024 / 1024 * 100) / 100} MB
                                            <br/>
                                            Estimated Time Left: {Math.floor(((recordedBlob.size / 1024 / 1024) * (100 - loadedPercentage) / 100) / recordingUploadRate)} Seconds
                                            <br/>
                                            Upload Rate: {Math.round(recordingUploadRate * 100) / 100} MB/s
                                        </div> : ""
                                    }
                                    <br/>
                                    <div className="text-3">If your network connection is slow or unstable, you may experience longer upload times. We appreciate your patience!</div>
                                </>
                            )))
                        }
                        onHelp={() => handleUploadVideo(`${scanID}_${inspection_type}_${pid}`)}
                        {...(recordedBlob ?
                                {
                                    extraElementEnd: <div className="mt-2">
                                        <Btn 
                                            type="secondary"
                                            style={{
                                                marginTop: '20px', 
                                                marginBottom: '10px'
                                            }} 
                                            text="Download Scan"
                                            onclick={downloadScanFile}
                                        />
                                        {step === STEP_UPLOAD_FAILED &&
                                            <Btn 
                                                type="secondary" 
                                                text="Retry Upload" 
                                                style={{marginTop: '10px', marginBottom: '10px'}} 
                                                onclick={() => {
                                                    switchStep(STEP_RETRY_FILE_UPLOAD);
                                                    uploadRecording(scanSess.current);
                                                }} 
                                            />
                                        }
                                        {debugMode &&
                                            <>
                                                <Btn type="secondary" text="Upload Scan" style={{marginTop: '10px', marginBottom: '10px'}} onclick={() => uploadRecording(scanSess.current)} />
                                                <div className="text-2">Upload Status: {uploadStatus}</div>
                                            </>
                                        }
                                    </div>
                                } : {}
                        )}
                    />
                ) : ""}
            {popupState ? <MasterPopup {...popupState[1]}>{popupState[0]}</MasterPopup> : ""}
            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState.msg}
                    type={notifState.type}/> : ""
            }
        </section>
    )
}
